









































































































































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import ImageInput from "@/components/ImageInput.vue";
import TagColorInput from "@/components/TagColorInput.vue";
import TagColorDisplay from "@/components/TagColorDisplay.vue";

export default Vue.extend({
  components: { ImageInput, TagColorInput, TagColorDisplay },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "Thông tin khai báo", value: "product_status" },
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("PACKAGE_BATCH_ID"), value: "package_batch_id" },
        {
          text: this.$t("USER_NAME"),
          value: "sale_name",
          sortable: false,
        },
        { text: this.$t("NAME"), value: "name" },
        { text: this.$t("PACKAGE_COUNT"), value: "count" },
        { text: this.$t("WEIGHT"), value: "weight" },
        { text: this.$t("VOLUME"), value: "volume" },
        { text: this.$t("INTERIOR_COST"), value: "interior_cost" },
        { text: this.$t("PACKAGE_METHOD"), value: "package_method" },
        { text: this.$t("NOTE"), value: "note" },
        { text: this.$t("IMAGE"), value: "images" },
        { text: this.$t("STORE_TIME"), value: "store_time" },
        { text: "Đã dán", value: "pasted" },
        { text: "Màu tag", value: "tag_color" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      options: {},
      search: "",
      searchTagColor: null,
      package_batch_id: "",
      filterStatus: "",
      loading: false,
      data: [],
      dataCount: 0,
      selectedItems: [],
      tagColorDialog: false,
      tagColorDialogForm: false,
      tagColorDialogValue: "",
      editedIndex: -1,
      editedItem: {
        package_id: "",
        package_batch_id: "",
        name: "",
        count: 0,
        weight: 0,
        volume: 0,
        interior_cost: 0,
        images: [],
      },
      defaultItem: {
        package_id: "",
        package_batch_id: "",
        name: "",
        count: 0,
        weight: 0,
        volume: 0,
        interior_cost: 0,
        images: [],
      },
      urlFunc(item) {
        return Vue.filter("packageImageUrl")(item);
      },
      availableStatuses: [
        {
          value: "",
          text: "Tất cả",
        },
        {
          value: "d",
          text: "Đủ",
        },
        {
          value: "dd",
          text: "Đã dán",
        },
      ],
      snackbar: false,
      fails: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("CREATE_PACKAGE")
        : this.$t("EDIT");
    },
    totalWeight() {
      let value = 0;
      for (const item of this.selectedItems) {
        value += item.weight;
      }
      return value;
    },
    totalVolume() {
      let value = 0;
      for (const item of this.selectedItems) {
        value += item.volume;
      }
      return value;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      const filters = [
        {
          key: "truck_id",
          operator: "equal_to",
          value: 0,
        },
        {
          key: "package_id",
          operator: "match",
          value: this.search,
        },
        {
          key: "package_batch_id",
          operator: "match",
          value: this.package_batch_id,
        },
        {
          key: "tag_color",
          operator: "equal_to",
          value: this.searchTagColor,
        },
      ];
      if (this.filterStatus) {
        switch (this.filterStatus) {
          case "d":
            filters.push({
              key: "product_status",
              operator: "=",
              value: "Approved",
            });
            break;
          case "dd":
            filters.push({
              key: "pasted",
              operator: "=",
              value: 1,
            });
            break;
        }
      }
      let zz = this.search && this.search.startsWith("ZZ")
      if(!zz){
        zz = !this.search && !this.searchTagColor && !this.package_batch_id && !["d","dd"].includes(this.filterStatus)
      }
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters,
        zz: zz ? true : undefined,
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.packageDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedIndex > -1) {
        result = await apiClient.packageUpdate(this.editedItem, [
          "package_id",
          "package_batch_id",
          "name",
          "count",
          "weight",
          "volume",
          "interior_cost",
          "package_method",
          "note",
          "images",
        ]);
      } else {
        result = await apiClient.packageCreate(this.editedItem);
      }
      if (!result.errors) {
        this.close();
        await this.initialize();
      } else {
        this.snackbar = true;
        this.fails = result.message;
      }
    },
    async packageLoad() {
      if (await apiClient.packageLoad(this.selectedItems)) {
        this.selectedItems = [];
        await this.initialize();
      }
    },
    async updatePasted(item) {
      await apiClient.packageUpdate({
        id: item.id,
        pasted: true,
      });
      await this.initialize();
    },
    async tagColorDialogSave() {
      const result = await apiClient.packageSetTagColor(
        this.selectedItems,
        this.tagColorDialogValue
      );
      if (result) {
        this.tagColorDialog = false;
        await this.initialize();
      }
    },
  },
});
